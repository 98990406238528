import "../../style/product.css";
import "../../style/productpage.css";
import Header from "../../components/Header";
import Footer from "../../components/footer";
import { useQuery } from "@apollo/client";
import { useCallback, useState } from "react";
import {
  GET_CATEGORIES,
  GET_PRODUCTS,
  GET_PRODUCTS_BY_CATEGORY,
} from "../../graphql/queries/productQueries";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import LoadingButton from "../../modules/icons/loading-button";
import PaginationArrowIcon from "../../modules/icons/pagination-arrow";
import { useInView } from "react-intersection-observer";
import Catalouge from "../../components/catalouge";
import RightArrrow from "../../modules/icons/rightarrow";
import { useEffect, useMemo } from "react";
import ReactDropdown from "react-dropdown";

const NewCategoryPage = () => {
  const newCategories = [
  "Air Con",
  "Air Cooler",
  "Fan",
  "Smart TV",
  "Pro Smart TV",
  "Speaker",
  "Refrigerator",
  "Washing Machine",
  "Freezer",
  "Showcase",
  "Clothes Dryer",
  "Water Purifier",
  "Water Dispenser",
  "Small Home Appliances",
  "Cooling Electronics",
  "Commercial Electronics",
  "Entertainment"
];
  const { category } = useParams();
  const [sortOrder, setSortOrder] = useState("default");
  const getOrderBy = () => {
    switch (sortOrder) {
      case "price_desc":
        return { price: "desc" };
      case "price_asc":
        return { price: "asc" };
      case "date_desc":
        return { created_at: "desc" };
      case "date_asc":
        return { created_at: "asc" };
      default:
        return {};
    }
  };
  const { data: categeories, loading: fetchCategory } =
    useQuery(GET_CATEGORIES);
    const { data: products, loading: fetchProducts } = useQuery(
      GET_PRODUCTS_BY_CATEGORY,
      {
        variables: { category, orderBy: getOrderBy() },
      }
    );
  const categoryLists = categeories ? categeories.category : [];
  const productLists = products ? products.product : [];
  const option = [
    { label: "Default Sorting", value: "default" },
    { label: "Sort By Price: Highest to Lowest", value: "price_desc" },
    { label: "Sort By Price: Lowest to Highest", value: "price_asc" },
    { label: "Sort By Date: Newest to Oldest", value: "date_desc" },
    { label: "Sort By Date: Oldest to Newest", value: "date_asc" },
  ];

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const itemsPerPage = 16;
  const [pagination, setPagination] = useState(1);
  const totalPages = Math.ceil(productLists.length / itemsPerPage);

  const handlePageChange = (direction) => {
    setPagination((prevPagination) => {
      const currentPage = prevPagination;
      const newPage =
        direction === "next" ? prevPagination + 1 : prevPagination - 1;
      if (newPage < 1 || newPage > totalPages) {
        return currentPage; // Do not change the page if out of bounds
      }
      return newPage;
    });
  };

  const currentItems = useMemo(() => {
    const start = (pagination - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return productLists.slice(start, end);
  }, [productLists, pagination, itemsPerPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, pagination]);

  const handlePageChangeByNumber = (pageNumber) => {
    setPagination(pageNumber);
  };
  const handleSortChange = (selectedOption) => {
    setSortOrder(selectedOption.value);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    if (totalPages <= 4) {
      // If there are 4 or fewer pages, show all of them
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={`category-pagination-button ${
              pagination === i ? "isactive" : ""
            }`}
            onClick={() => handlePageChangeByNumber(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      // Show first 3 pages
      for (let i = 1; i <= 3; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={`category-pagination-button ${
              pagination === i ? "isactive" : ""
            }`}
            onClick={() => handlePageChangeByNumber(i)}
          >
            {i}
          </button>
        );
      }

      // Show ellipsis if the current page is beyond the first 3 pages
      if (pagination > 4) {
        pageNumbers.push(<span key="ellipsis-1">...</span>);
      }

      // Show the current page number if it's not among the first 3 or last page
      if (pagination > 3 && pagination < totalPages) {
        pageNumbers.push(
          <button
            key={pagination}
            className={`category-pagination-button isactive`}
            onClick={() => handlePageChangeByNumber(pagination)}
          >
            {pagination}
          </button>
        );
      }

      // Show ellipsis if the current page is more than 1 page away from the last page
      if (pagination < totalPages - 2) {
        pageNumbers.push(<span key="ellipsis-2">...</span>);
      }

      // Show the last page number
      pageNumbers.push(
        <button
          key={totalPages}
          className={`category-pagination-button ${
            pagination === totalPages ? "isactive" : ""
          }`}
          onClick={() => handlePageChangeByNumber(totalPages)}
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  if (fetchCategory || fetchProducts)
    return (
      <div className="loading-container">
        <LoadingButton />
        Loading...
      </div>
    );
  return (
    <div
      style={{
        backgroundColor: "#B1B3B6",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Header />
      <div className="all-proudcts-header">
        <div className="all-proudcts-header-layout">
          <h1>{category
                  .toLowerCase()
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}</h1>
          <div className="all-proudcts-header-nav-list">
            <p style={{ lineHeight: "0", cursor: "pointer" }} onClick={() => navigate("/")}>Home</p>
            <span style={{ color: "white" }}>/</span>

            <p
              onClick={() => navigate("/products")}
              style={{ lineHeight: "0", cursor: "pointer" }}
            >
              Shop
            </p>
            <span style={{color:'white'}}>/</span>
            <p>{category
                  .toLowerCase()
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}</p>
          </div>
        </div>
      </div>
      <section className="all-proudcts-container">
        <div className="all-proudcts-container-left">
          <h3>Product Categories</h3>
          <div className="all-proudcts-container-left-lists">
            {newCategories.map((category,index) => (
              <div
                className="all-proudcts-container-left-list"
                onClick={() =>
                  navigate(`/allproduct/${category.toLowerCase()}`)
                }
                key={index}
              >
               {category
                  .toLowerCase()
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </div>
            ))}
          </div>
        </div>
        <div className="all-proudcts-container-right">
          <div className="all-proudcts-container-right-layout">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <p style={{ lineHeight: "0", cursor: "pointer" }} onClick={() => navigate("/")}>Home</p>
              <span>/</span>
              <p
                onClick={() => navigate("/products")}
                style={{ lineHeight: "0", cursor: "pointer" }}
              >
                Shop
              </p>
              <span>/</span>
              <p style={{ lineHeight: "0", color: "blue" }}>
              {category
                  .toLowerCase()
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </p>
            </div>
            <div>
              <h2 style={{ fontSize: "2rem" }}>{category.toUpperCase()}</h2>
            </div>
            <div className="sorting-dropdown-container">
              <div className="sorting-text">
                <div style={{fontWeight:'600'}}>Displaying <span style={{color:'black',fontWeight:'600'}}>{pagination}</span> of <span style={{color:'black',fontWeight:'600'}}s>{totalPages}</span> pages</div>
              </div>
            <div className="sorting-dropdown">
                <ReactDropdown  onChange={handleSortChange} options={option} placeholder="Sort Products"/> 
              </div>
            </div>
            <div className="all-proudcts-container-right-product-list-container">
              {currentItems.map((product) => (
                <div className="individual-product-container-layout">
                  <div
                    key={product.id}
                    onClick={() =>
                      navigate(`/products/productdetail/${product.id}`)
                    }
                    class="category-individual-product-container"
                  >
                    <div class="category-individual-product-img">
                      <img src={product.image_url} />
                    </div>
                    <div class="category-individual-product-description">
                      <p class="product-type">
                        {product.category.category_name}
                      </p>
                      <p class="product-name">{product.name}</p>
                      <p class="product-price">
                        {product.price} <span>KS</span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
              {
                currentItems.length < 1 ?(<div className="outofstock">
                  No product for this category
                </div>):(<div></div>)
              }
            </div>
          </div>
          <div className="pagination-control-container">
            <div className="pagination-control-layout">
              <button
                className={`category-prev-btn ${
                  pagination === 1 ? "disable" : ""
                }`}
                disabled={pagination === 1}
                onClick={() => handlePageChange("prev")}
              >
                {" "}
                <PaginationArrowIcon
                  height={24}
                  width={24}
                  color={`${pagination === 1 ? "#C1C1C1" : "#262626"}`}
                />
              </button>
              {renderPageNumbers()}{" "}
              <button
                className={`category-next-btn ${
                  pagination === totalPages ? "disable" : ""
                }`}
                disabled={pagination === totalPages}
                onClick={() => handlePageChange("next")}
              >
                {" "}
                <PaginationArrowIcon
                  height={24}
                  width={24}
                  color={`${pagination === totalPages ? "#C1C1C1" : "#262626"}`}
                />
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className="footer-divider"></div>
      <Footer />
    </div>
  );
};
export default NewCategoryPage;
