import { useQuery } from "@apollo/client";
import {
  GET_ALL_MESSAGES,
  GET_FILTERED_MESSAGES,
} from "../../graphql/queries/messageQueries";
import "../../style/message.css";
import { useState } from "react";
import { MdOutlineMarkAsUnread } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Message = () => {
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("all"); // 'all', 'read', 'unread'
  const messagesPerPage = 10;
  const offset = (page - 1) * messagesPerPage;
  const navigate = useNavigate();
  const location = useLocation();

  // Determine the query based on the filter
  const isAllMessages = filter === "all";
  const readFilter =
    filter === "read" ? true : filter === "unread" ? false : undefined;

  const {
    data,
    loading: getMessageLoading,
    refetch,
  } = useQuery(isAllMessages ? GET_ALL_MESSAGES : GET_FILTERED_MESSAGES, {
    variables: isAllMessages
      ? { limit: messagesPerPage, offset }
      : { limit: messagesPerPage, offset, read: readFilter },
  });

  useEffect(() => {
    if (location.state?.refetch) {
      refetch();
    }
  }, [location.state, refetch]);

  const messages = data ? data.message : [];
  const totalMessages = data?.message_aggregate?.aggregate?.count || 0;
  const totalPages = Math.ceil(totalMessages / messagesPerPage);

  // Handle page change
  const handleNextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  // Handle filter change
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setPage(1); // Reset to page 1 when filter is applied
  };

  if (getMessageLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="message-container">
      <div className="message-container-layout">
        <div className="message-header">
          <div>No</div>
          <div>Sender</div>
          <div>Phone Number</div>
          <div>Status</div>
          <div className="filter-checkbox-container">
            {/* Filter Checkboxes */}
            <label>
              <input
                type="radio"
                value="all"
                checked={filter === "all"}
                onChange={handleFilterChange}
              />
              All
            </label>
            <label>
              <input
                type="radio"
                value="unread"
                checked={filter === "unread"}
                onChange={handleFilterChange}
              />
              Unread
            </label>
            <label>
              <input
                type="radio"
                value="read"
                checked={filter === "read"}
                onChange={handleFilterChange}
              />
              Read
            </label>
          </div>
        </div>

        <div className="message-list-container">
          {messages.map((message, index) => (
            <div
              key={message.message_id}
              className="individual-message-description"
            >
              <div>{offset + index + 1}</div>
              <div>{message.username}</div>
              <div>{message.ph_number}</div>
              <div className="message-status" style={{color:`${message.read ? "gray" : "purple"}`, fontWeight:`${message.read ? "400" : "600"}`}}>
                {message.read ? "Read" : "Unread"}
              </div>
              <div
                onClick={() => navigate(`${message.message_id}`)}
                className="read-message-text"
              >
                Read <MdOutlineMarkAsUnread size={20} />
              </div>
            </div>
          ))}
        </div>

        {/* Pagination Controls */}
        <div className="pagination-controls">
          <button disabled={page === 1} onClick={handlePreviousPage}>
            Previous
          </button>
          <span>
            {page}
            <span style={{ padding: "5px" }}>/</span>
            {totalPages}
          </span>
          <button disabled={page === totalPages} onClick={handleNextPage}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Message;
