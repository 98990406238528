import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import "../../../style/messagedetail.css";
import { IoIosMailUnread } from "react-icons/io";
import { ImBin } from "react-icons/im";
import { GET_MESSAGE_BY_ID } from "../../../graphql/queries/messageQueries";
import {
  DELETE_MESSAGE_BY_ID,
  UPDATE_MESSAGE_READ_STATUS,
} from "../../../graphql/mutation/messageMutation";
import LoadingButton from "../../../modules/icons/loading-button";
import { TiTick } from "react-icons/ti";

const DetailMessage = () => {
  const { message_id } = useParams();
  const navigate = useNavigate();

  const {
    data,
    loading,
    error,
    refetch: refetchMessage,
  } = useQuery(GET_MESSAGE_BY_ID, {
    variables: { id: message_id },
  });

  const [deleteMessage, { loading: deleteMessageLoading }] = useMutation(
    DELETE_MESSAGE_BY_ID,
    {
      onCompleted: () => {
        navigate("/dashboard/message", { state: { refetch: true } }); // Redirect to the messages list after deletion
      },
      onError: (error) => {
        console.error("Error deleting message:", error);
      },
    }
  );

  const [updateMessageReadStatus, { loading: updateMessageLoading }] =
    useMutation(UPDATE_MESSAGE_READ_STATUS, {
      onCompleted: () => {
        // Optionally refetch or update the cache here
        refetchMessage();
      },
      onError: (error) => {
        console.error("Error updating message read status:", error);
      },
    });

  const handleMarkAsRead = () => {
    updateMessageReadStatus({ variables: { id: message_id, read: true } });
  };

  const handleDelete = () => {
    deleteMessage({ variables: { id: message_id } });
  };

  const formatDateTime = (datetime) => {
    if (!datetime) return "";
    const date = new Date(datetime);
    return date.toLocaleString(); // Adjust this as needed for your format
  };

  if (loading)
    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <LoadingButton />
        Loading...
      </div>
    );
  if (error) return <div>Error loading message</div>;

  const message = data?.message_by_pk;

  return (
    <div className="message-detail-container">
      <div className="message-detail-container-layout">
        <div className="message-container-header">
          <div className="message-back-btn">
            <button
              onClick={() =>
                navigate("/dashboard/message", { state: { refetch: true } })
              }
            >
              Back
            </button>
          </div>
          <div className="message-header-right">
            <button
              onClick={handleMarkAsRead}
              disabled={message?.read || updateMessageLoading}
            >
              {!message.read ? (
                <div className="mark-as-read">
                  {updateMessageLoading ? (
                    <LoadingButton />
                  ) : (
                    <div className="mark-as-read">
                      <IoIosMailUnread size={15} /> Mark as read
                    </div>
                  )}
                </div>
              ) : (
                <div style={{ color: "greenyellow" }} className="mark-as-read">
                  {/* {updateMessageLoading ? (
                    <LoadingButton />
                  ) : (
                    <div className="mark-as-read">
                      <TiTick size={15} /> Read
                    </div>
                  )} */}
                   <div className="mark-as-read">
                      <TiTick size={15} /> Read
                    </div>
                </div>
              )}
            </button>
            <button onClick={handleDelete} disabled={deleteMessageLoading}>
              <div className="read-btn">
                {deleteMessageLoading ? <LoadingButton /> : <ImBin size={12} />}{" "}
                {deleteMessageLoading ? "Deleting" : "Delete"}
              </div>
            </button>
          </div>
        </div>
        <div className="message-section">
          <div className="message-description">
            <div style={{ fontWeight: "700" }}>Message Sender:</div>
            <div>{message.username}</div>
          </div>
          <div className="message-description">
            <div style={{ fontWeight: "700" }}>Phone Number:</div>
            <div>{message.ph_number}</div>
          </div>
          <div className="message-description">
            <div style={{ fontWeight: "700" }}>Sent at:</div>
            <div>{formatDateTime(message.created_at)}</div>
          </div>
          <div className="message-description">
            <div style={{ fontWeight: "700" }}>Message:</div>
            <div>{message.message}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailMessage;
