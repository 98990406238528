const ClockIcon = ({width,height}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6417 15.7023C14.9346 15.9952 15.4094 15.9952 15.7023 15.7023C15.9952 15.4094 15.9952 14.9346 15.7023 14.6417L14.6417 15.7023ZM12.005 12.005H11.255V12.3157L11.4747 12.5353L12.005 12.005ZM12.755 5.672C12.755 5.25779 12.4192 4.922 12.005 4.922C11.5908 4.922 11.255 5.25779 11.255 5.672H12.755ZM12 21.5V20.75V21.5ZM21.5 12H22.25H21.5ZM12 2.5V1.75V2.5ZM2.5 12H1.75H2.5ZM15.7023 14.6417L12.5353 11.4747L11.4747 12.5353L14.6417 15.7023L15.7023 14.6417ZM12.755 12.005V5.672H11.255V12.005H12.755ZM12 22.25C14.7185 22.25 17.3256 21.1701 19.2478 19.2478L18.1872 18.1872C16.5462 19.8281 14.3206 20.75 12 20.75V22.25ZM19.2478 19.2478C21.1701 17.3256 22.25 14.7185 22.25 12H20.75C20.75 14.3206 19.8281 16.5462 18.1872 18.1872L19.2478 19.2478ZM22.25 12C22.25 9.28153 21.1701 6.6744 19.2478 4.75216L18.1872 5.81282C19.8281 7.45376 20.75 9.67936 20.75 12H22.25ZM19.2478 4.75216C17.3256 2.82991 14.7185 1.75 12 1.75V3.25C14.3206 3.25 16.5462 4.17187 18.1872 5.81282L19.2478 4.75216ZM12 1.75C9.28153 1.75 6.6744 2.82991 4.75216 4.75216L5.81282 5.81282C7.45376 4.17187 9.67936 3.25 12 3.25V1.75ZM4.75216 4.75216C2.82991 6.6744 1.75 9.28153 1.75 12H3.25C3.25 9.67936 4.17187 7.45376 5.81282 5.81282L4.75216 4.75216ZM1.75 12C1.75 14.7185 2.82991 17.3256 4.75216 19.2478L5.81282 18.1872C4.17187 16.5462 3.25 14.3206 3.25 12H1.75ZM4.75216 19.2478C6.6744 21.1701 9.28153 22.25 12 22.25V20.75C9.67936 20.75 7.45376 19.8281 5.81282 18.1872L4.75216 19.2478Z"
        fill="#4A4CCD"
      />
    </svg>
  );
};
export default ClockIcon;
