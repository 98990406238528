const AppleStore = ({width,height}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 197 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_22_916)">
        <path
          d="M196.5 50.9265C196.501 51.6633 196.356 52.393 196.073 53.0733C195.79 53.7535 195.374 54.3708 194.851 54.8892C194.327 55.4077 193.706 55.817 193.023 56.0935C192.34 56.37 191.609 56.5081 190.872 56.4999H5.99319C5.26746 56.5134 4.54646 56.3803 3.87339 56.1086C3.20032 55.8368 2.58902 55.432 2.0761 54.9183C1.56319 54.4046 1.15923 53.7927 0.888401 53.1192C0.617576 52.4457 0.485464 51.7244 0.49998 50.9986V5.98377C0.486437 5.25905 0.619345 4.53906 0.890737 3.86695C1.16213 3.19484 1.56642 2.58446 2.07937 2.07238C2.59232 1.5603 3.20337 1.15708 3.8759 0.886873C4.54843 0.61667 5.26858 0.485053 5.99319 0.499911H190.872C193.993 0.499911 196.473 2.87252 196.473 5.99123V51.0061L196.5 50.9265Z"
          fill="#1C1A16"
        />
        <path
          d="M43.4187 28.1805C43.3764 23.4328 47.308 21.1199 47.487 21.0129C45.2614 17.7649 41.8098 17.3222 40.5962 17.2824C37.6992 16.979 34.8767 19.0233 33.4145 19.0233C31.9523 19.0233 29.6297 17.3172 27.1828 17.367C24.0296 17.4192 21.0778 19.2447 19.4739 22.0774C16.1242 27.8572 18.6134 36.3528 21.8114 41.0383C23.4129 43.3264 25.2928 45.883 27.7298 45.791C30.1221 45.6915 31.0148 44.2665 33.9069 44.2665C36.799 44.2665 37.6097 45.791 40.1064 45.7338C42.6752 45.6915 44.2915 43.4333 45.8259 41.1254C46.9776 39.4957 47.8631 37.6934 48.4494 35.7858C48.3872 35.7659 43.4585 33.8857 43.4087 28.1904"
          fill="white"
        />
        <path
          d="M38.7044 14.2133C39.9927 12.6042 40.8756 10.4106 40.6269 8.18726C38.7641 8.27181 36.4338 9.47553 35.0933 11.0523C33.9045 12.4425 32.8401 14.7181 33.1037 16.857C35.2002 17.0112 37.3465 15.8 38.692 14.2133"
          fill="white"
        />
        <path
          d="M78.069 45.4551H74.7243L72.8916 39.6902H66.523L64.7823 45.4551H61.5247L67.841 25.8425H71.7352L78.069 45.4551ZM72.3494 37.2753L70.6933 32.1546C70.5159 31.6323 70.1843 30.4012 69.6986 28.4614H69.6314C69.4358 29.2904 69.125 30.5214 68.6989 32.1546L67.0676 37.2753H72.3494Z"
          fill="white"
        />
        <path
          d="M94.3049 38.208C94.3049 40.6121 93.655 42.513 92.3552 43.9107C91.8081 44.5092 91.1395 44.9841 90.3942 45.3036C89.6489 45.6231 88.844 45.7799 88.0333 45.7635C86.1682 45.7635 84.8287 45.0945 84.0147 43.7565H83.96V51.2051H80.8068V35.9597C80.8068 34.4476 80.7679 32.8957 80.6899 31.304H83.4527L83.6293 33.5423H83.689C84.7334 31.8528 86.3232 31.0089 88.4585 31.0105C89.26 31.0021 90.0529 31.1769 90.7766 31.5217C91.5002 31.8666 92.1354 32.3722 92.6338 33.0002C93.7478 34.3266 94.3049 36.0625 94.3049 38.208ZM91.1044 38.3224C91.1044 36.9479 90.7944 35.813 90.1744 34.9176C89.8709 34.4754 89.4617 34.1161 88.984 33.8724C88.5062 33.6287 87.9751 33.5084 87.439 33.5224C86.666 33.5208 85.9177 33.7941 85.3277 34.2934C84.6956 34.8076 84.2571 35.5215 84.0843 36.3178C83.9986 36.6302 83.9502 36.9516 83.9401 37.2753V39.633C83.9129 40.5891 84.2498 41.5198 84.8826 42.2369C85.1885 42.5847 85.5678 42.8604 85.9931 43.044C86.4183 43.2276 86.879 43.3147 87.342 43.2989C87.8804 43.3217 88.4158 43.2083 88.8987 42.9692C89.3817 42.7301 89.7965 42.373 90.1047 41.931C90.7679 41.0174 91.0994 39.8146 91.0994 38.3224"
          fill="white"
        />
        <path
          d="M110.566 38.208C110.566 40.6121 109.916 42.513 108.616 43.9107C108.068 44.5109 107.397 44.9869 106.649 45.3064C105.902 45.626 105.095 45.7819 104.282 45.7635C102.422 45.7635 101.085 45.0945 100.271 43.7565H100.218V51.2051H97.0701V35.9597C97.0701 34.4476 97.0312 32.8957 96.9532 31.304H99.721L99.8975 33.5423H99.9572C101 31.8528 102.59 31.0089 104.727 31.0106C105.528 31.0019 106.321 31.1766 107.045 31.5215C107.769 31.8663 108.404 32.3721 108.902 33.0002C110.016 34.3266 110.572 36.0625 110.571 38.208M107.373 38.3224C107.373 36.9479 107.062 35.813 106.44 34.9177C106.137 34.4752 105.728 34.1158 105.25 33.8721C104.772 33.6284 104.241 33.5082 103.705 33.5224C102.933 33.5207 102.185 33.7941 101.596 34.2934C100.963 34.8068 100.524 35.521 100.353 36.3178C100.265 36.6298 100.216 36.9514 100.206 37.2753V39.633C100.178 40.5896 100.516 41.5207 101.151 42.2369C101.456 42.5853 101.836 42.8613 102.261 43.045C102.686 43.2287 103.147 43.3154 103.61 43.2989C104.149 43.3215 104.684 43.208 105.167 42.9689C105.65 42.7298 106.065 42.3729 106.373 41.931C107.03 41.0183 107.368 39.8171 107.368 38.3224"
          fill="white"
        />
        <path
          d="M128.751 39.9539C128.751 41.6202 128.174 42.9781 127.01 44.0276C125.732 45.1717 123.953 45.7437 121.671 45.7437C119.563 45.7437 117.868 45.3366 116.586 44.5226L117.315 41.9037C118.684 42.7317 120.258 43.1623 121.858 43.1472C123.042 43.1472 123.964 42.8811 124.626 42.3489C124.947 42.0942 125.205 41.7675 125.378 41.3952C125.551 41.0229 125.634 40.6153 125.62 40.2051C125.629 39.8324 125.561 39.4619 125.421 39.1164C125.281 38.7708 125.073 38.4575 124.807 38.1956C124.265 37.651 123.36 37.1461 122.099 36.6785C118.666 35.3952 116.95 33.5209 116.952 31.0554C116.932 30.3011 117.084 29.5523 117.396 28.8654C117.709 28.1786 118.173 27.5719 118.754 27.0911C119.956 26.0615 121.557 25.5458 123.556 25.5442C125.342 25.5442 126.825 25.8551 128.005 26.4768L127.222 29.0384C126.067 28.4243 124.775 28.1136 123.467 28.1357C122.359 28.1357 121.497 28.4076 120.881 28.9514C120.63 29.1731 120.43 29.4462 120.294 29.7523C120.159 30.0583 120.091 30.3899 120.095 30.7246C120.087 31.0923 120.165 31.4568 120.321 31.7898C120.477 32.1228 120.708 32.4153 120.995 32.6446C121.517 33.1088 122.468 33.6137 123.847 34.1592C125.533 34.8373 126.777 35.6323 127.577 36.5442C128.386 37.4907 128.811 38.7049 128.771 39.9489"
          fill="white"
        />
        <path
          d="M139.137 33.6693H135.675V40.5409C135.675 42.2868 136.287 43.1589 137.51 43.1573C137.979 43.1677 138.447 43.1193 138.903 43.013L138.99 45.4006C138.203 45.6582 137.376 45.776 136.548 45.7487C135.306 45.7487 134.336 45.3707 133.638 44.6147C132.94 43.8586 132.591 42.5836 132.591 40.7896V33.6693H130.524V31.3092H132.591V28.7227L135.675 27.79V31.3092H139.137V33.6693Z"
          fill="white"
        />
        <path
          d="M154.76 38.2652C154.76 40.4388 154.14 42.2237 152.9 43.6197C151.599 45.0572 149.87 45.7718 147.715 45.7635C145.636 45.7635 143.982 45.0754 142.754 43.6993C141.526 42.3231 140.91 40.588 140.906 38.494C140.906 36.2971 141.541 34.5023 142.811 33.1096C144.081 31.7169 145.799 31.0205 147.964 31.0205C150.038 31.0205 151.706 31.7094 152.97 33.0872C154.173 34.4269 154.774 36.1529 154.772 38.2652M151.512 38.3671C151.546 37.1924 151.255 36.0312 150.669 35.0122C150.006 33.8831 149.066 33.3185 147.849 33.3185C147.259 33.3016 146.675 33.4515 146.166 33.7511C145.657 34.0506 145.242 34.4877 144.97 35.0122C144.38 36.051 144.088 37.2327 144.127 38.4268C144.088 39.6012 144.376 40.7634 144.957 41.7843C145.634 42.9117 146.584 43.4755 147.81 43.4755C149.008 43.4755 149.951 42.9034 150.627 41.7544C151.228 40.7297 151.53 39.5572 151.5 38.3696"
          fill="white"
        />
        <path
          d="M164.971 34.0746C164.643 34.0164 164.31 33.9873 163.977 33.9876C163.479 33.9646 162.984 34.0663 162.537 34.2835C162.089 34.5007 161.702 34.8264 161.413 35.2311C160.87 36.061 160.602 37.0407 160.647 38.0315V45.4527H157.496L157.523 35.7658C157.523 34.1343 157.485 32.6496 157.408 31.3116H160.144L160.259 34.0199H160.343C160.642 33.1379 161.188 32.3607 161.917 31.7816C162.574 31.2947 163.371 31.033 164.188 31.0355C164.45 31.0339 164.712 31.053 164.971 31.0927V34.0746Z"
          fill="white"
        />
        <path
          d="M179.021 37.7131C179.028 38.1906 178.99 38.6677 178.909 39.1382H169.484C169.519 40.5359 169.974 41.6036 170.849 42.3415C171.643 43.0005 172.672 43.3363 173.933 43.3363C175.234 43.3531 176.527 43.1271 177.745 42.6697L178.242 44.8508C176.738 45.4658 175.123 45.7638 173.498 45.7263C171.309 45.7263 169.589 45.0805 168.335 43.7889C167.082 42.4973 166.457 40.7663 166.46 38.596C166.46 36.4622 167.04 34.6864 168.201 33.2688C169.421 31.7567 171.069 31.0015 173.145 31.0032C175.182 31.0032 176.725 31.7584 177.775 33.2688C178.604 34.4726 179.018 35.9573 179.018 37.7231M176.034 36.9073C176.076 36.06 175.865 35.2192 175.427 34.4924C174.883 33.6195 174.045 33.1843 172.921 33.1843C172.431 33.1763 171.947 33.2889 171.511 33.5123C171.075 33.7357 170.701 34.0629 170.422 34.4651C169.885 35.1733 169.563 36.0211 169.494 36.9073H176.034Z"
          fill="white"
        />
        <path
          d="M71.179 13.7756C71.179 15.5115 70.6601 16.8172 69.6221 17.6926C68.6588 18.5 67.2918 18.9038 65.5211 18.9038C64.7658 18.9112 64.0108 18.8721 63.2604 18.7869V9.31634C64.1399 9.17961 65.0289 9.11308 65.919 9.11738C67.6085 9.11738 68.8818 9.48463 69.739 10.2191C70.699 11.0481 71.179 12.2336 71.179 13.7756ZM69.5525 13.8203C69.5525 12.6937 69.2516 11.8307 68.6572 11.2313C68.0628 10.632 67.1948 10.331 66.0483 10.331C65.6315 10.3255 65.2151 10.3596 64.8048 10.433V17.6453C65.1509 17.6817 65.499 17.6958 65.8469 17.6876C67.0282 17.6876 67.9434 17.3618 68.5826 16.6928C69.2217 16.0238 69.5525 15.0787 69.5525 13.8203Z"
          fill="white"
        />
        <path
          d="M79.8128 15.2929C79.8468 16.2489 79.5201 17.1828 78.8976 17.9092C78.5768 18.2639 78.182 18.5438 77.741 18.7291C77.3001 18.9143 76.8238 19.0003 76.3459 18.9811C75.8899 19.0014 75.4349 18.9207 75.0136 18.7449C74.5923 18.5691 74.215 18.3024 73.9087 17.9639C73.2922 17.258 72.9677 16.3438 73.0009 15.4073C72.9624 14.4376 73.2981 13.4902 73.9385 12.7611C74.5603 12.0747 75.4017 11.7323 76.4628 11.7339C76.9211 11.7149 77.378 11.7957 77.8019 11.971C78.2258 12.1462 78.6065 12.4115 78.9175 12.7486C79.5286 13.4517 79.8472 14.3621 79.8079 15.2929M78.2062 15.3426C78.2209 14.7654 78.0772 14.1951 77.7909 13.6937C77.6607 13.4389 77.4616 13.2258 77.2162 13.0785C76.9709 12.9312 76.6892 12.8557 76.4031 12.8606C76.1132 12.8528 75.8269 12.9268 75.5771 13.0741C75.3273 13.2215 75.124 13.4362 74.9905 13.6937C74.6998 14.2033 74.556 14.7835 74.5752 15.3699C74.5582 15.9482 74.7019 16.5199 74.9905 17.0213C75.1261 17.2735 75.3278 17.484 75.5739 17.6302C75.8201 17.7765 76.1014 17.8529 76.3877 17.8514C76.674 17.8499 76.9545 17.7704 77.1991 17.6215C77.4437 17.4727 77.6431 17.26 77.776 17.0064C78.0716 16.5027 78.2207 15.9265 78.2062 15.3426Z"
          fill="white"
        />
        <path
          d="M91.3879 11.8755L89.212 18.8217H87.7995L86.8993 15.8075C86.6765 15.0711 86.4905 14.3241 86.3423 13.5691H86.315C86.1785 14.3269 85.9916 15.0746 85.7554 15.8075L84.8005 18.8217H83.3707L81.3191 11.8755H82.9056L83.6964 15.1733C83.8879 15.9542 84.0446 16.6953 84.1689 17.4116H84.1987C84.3131 16.8296 84.4996 16.091 84.7707 15.1956L85.7654 11.8805H87.0212L87.9662 15.1136C88.1983 15.9061 88.3839 16.6688 88.5232 17.4016H88.5655C88.682 16.6309 88.8406 15.867 89.0404 15.1136L89.8859 11.8805L91.3879 11.8755Z"
          fill="white"
        />
        <path
          d="M99.3934 18.8219H97.8489V14.8427C97.8489 13.6141 97.3839 13.0048 96.4488 13.0048C96.2372 12.9982 96.0269 13.0396 95.8335 13.1257C95.6401 13.2118 95.4687 13.3405 95.3321 13.5022C95.0501 13.8354 94.8981 14.2594 94.9043 14.696V18.8219H93.3599V13.8628C93.3599 13.251 93.3417 12.5878 93.3052 11.8732H94.6606L94.7352 12.96H94.7775C94.9642 12.6137 95.2409 12.3242 95.5783 12.1219C96.0008 11.8605 96.4891 11.725 96.9859 11.7314C97.2838 11.7226 97.5804 11.7738 97.8581 11.8819C98.1358 11.99 98.389 12.1528 98.6025 12.3607C99.1372 12.8755 99.4033 13.6464 99.4033 14.6636L99.3934 18.8219Z"
          fill="white"
        />
        <path
          d="M103.653 8.68726H102.106V18.8218H103.653V8.68726Z"
          fill="white"
        />
        <path
          d="M112.742 15.2928C112.778 16.249 112.451 17.1833 111.827 17.9091C111.506 18.2615 111.112 18.5393 110.672 18.7227C110.232 18.9062 109.757 18.991 109.28 18.971C108.825 18.9915 108.37 18.9109 107.949 18.735C107.528 18.5592 107.151 18.2924 106.846 17.9539C106.232 17.2505 105.907 16.3404 105.938 15.4072C105.899 14.4378 106.234 13.4905 106.873 12.761C107.496 12.0746 108.339 11.7322 109.4 11.7338C109.858 11.7151 110.315 11.7961 110.739 11.9713C111.163 12.1465 111.543 12.4117 111.854 12.7485C112.464 13.4525 112.781 14.3625 112.742 15.2928ZM111.143 15.3425C111.158 14.7653 111.014 14.195 110.728 13.6936C110.598 13.4388 110.399 13.2257 110.153 13.0784C109.908 12.9311 109.626 12.8556 109.34 12.8605C109.05 12.8532 108.763 12.9274 108.512 13.0746C108.262 13.2219 108.058 13.4363 107.923 13.6936C107.636 14.2046 107.493 14.784 107.51 15.3699C107.496 15.9481 107.64 16.5191 107.928 17.0212C108.062 17.2749 108.264 17.4865 108.511 17.6331C108.758 17.7796 109.041 17.8553 109.328 17.8519C109.615 17.854 109.897 17.776 110.143 17.6267C110.389 17.4773 110.588 17.2625 110.718 17.0063C111.012 16.5022 111.161 15.9262 111.148 15.3425"
          fill="white"
        />
        <path
          d="M120.217 18.8217H118.83L118.715 18.0234H118.673C118.439 18.3371 118.132 18.5883 117.778 18.7549C117.424 18.9215 117.034 18.9982 116.643 18.9784C116.349 18.9913 116.056 18.9419 115.782 18.8335C115.508 18.7251 115.26 18.56 115.054 18.3492C114.875 18.161 114.736 17.9393 114.643 17.6969C114.55 17.4544 114.507 17.196 114.515 16.9365C114.5 16.5462 114.591 16.1591 114.779 15.8166C114.967 15.4741 115.244 15.189 115.582 14.9917C116.288 14.5416 117.285 14.3252 118.566 14.3326V14.2058C118.566 13.2906 118.087 12.833 117.128 12.833C116.486 12.8215 115.856 13.0006 115.315 13.3478L115.002 12.3331C115.722 11.9148 116.545 11.7071 117.377 11.7337C119.2 11.7337 120.113 12.6912 120.113 14.6062V17.1629C120.104 17.7168 120.138 18.2706 120.215 18.8192M118.621 16.4342V15.3648C116.917 15.3349 116.064 15.805 116.064 16.7649C116.053 16.9141 116.074 17.064 116.126 17.2043C116.178 17.3446 116.259 17.4721 116.365 17.5782C116.57 17.7601 116.837 17.857 117.111 17.8493C117.453 17.8478 117.784 17.7352 118.056 17.5285C118.333 17.3344 118.524 17.04 118.588 16.7077C118.609 16.618 118.618 16.5261 118.616 16.4342"
          fill="white"
        />
        <path
          d="M128.993 18.8219H127.62L127.548 17.7077H127.506C127.068 18.5549 126.322 18.9794 125.268 18.981C124.867 18.9865 124.47 18.8996 124.108 18.7271C123.746 18.5546 123.429 18.3011 123.181 17.9862C122.606 17.2608 122.312 16.3521 122.353 15.4271C122.353 14.303 122.649 13.3927 123.248 12.6914C123.513 12.3821 123.842 12.1349 124.213 11.9672C124.584 11.7996 124.987 11.7157 125.394 11.7215C126.33 11.7215 126.983 12.0356 127.354 12.664H127.384V8.68481H128.926V16.9491C128.926 17.6289 128.945 18.2532 128.983 18.8219M127.384 15.8922V14.7357C127.388 14.5687 127.373 14.4018 127.339 14.2383C127.259 13.8736 127.062 13.5451 126.777 13.3032C126.495 13.0564 126.13 12.925 125.755 12.9351C125.49 12.9263 125.227 12.9843 124.99 13.1038C124.753 13.2233 124.55 13.4005 124.4 13.619C124.051 14.1365 123.877 14.7516 123.902 15.3749C123.875 15.9699 124.043 16.5576 124.38 17.0486C124.529 17.2662 124.73 17.4429 124.965 17.5628C125.2 17.6827 125.461 17.7419 125.725 17.735C125.959 17.7422 126.192 17.6942 126.404 17.5948C126.616 17.4955 126.802 17.3475 126.946 17.163C127.246 16.8084 127.405 16.3563 127.394 15.8922"
          fill="white"
        />
        <path
          d="M142.203 15.2928C142.237 16.2498 141.909 17.1844 141.283 17.9091C140.962 18.2614 140.569 18.5391 140.13 18.7226C139.691 18.9061 139.216 18.991 138.741 18.9711C138.285 18.9917 137.83 18.9112 137.408 18.7354C136.987 18.5595 136.61 18.2926 136.304 17.9539C135.687 17.2479 135.363 16.3338 135.396 15.3972C135.357 14.4279 135.691 13.4806 136.331 12.7511C136.651 12.4067 137.043 12.1365 137.478 11.9591C137.914 11.7817 138.383 11.7014 138.853 11.7239C139.312 11.7054 139.77 11.7865 140.195 11.9617C140.62 12.1368 141.002 12.4019 141.315 12.7386C141.923 13.4432 142.241 14.3528 142.203 15.2828M140.599 15.3326C140.614 14.7552 140.471 14.1847 140.183 13.6837C140.053 13.4293 139.855 13.2164 139.61 13.0692C139.365 12.9219 139.084 12.8462 138.798 12.8505C138.508 12.8426 138.221 12.9165 137.971 13.0638C137.721 13.2112 137.517 13.426 137.383 13.6837C137.093 14.1938 136.95 14.7737 136.968 15.3599C136.952 15.9381 137.095 16.5094 137.383 17.0113C137.518 17.2635 137.72 17.474 137.966 17.6202C138.212 17.7665 138.494 17.8429 138.78 17.8414C139.066 17.8398 139.347 17.7604 139.591 17.6115C139.836 17.4626 140.035 17.25 140.168 16.9964C140.465 16.4932 140.614 15.9166 140.599 15.3326Z"
          fill="white"
        />
        <path
          d="M150.503 18.8218H148.959V14.8426C148.959 13.614 148.491 13.0047 147.561 13.0047C147.35 12.9974 147.139 13.0384 146.946 13.1245C146.752 13.2107 146.581 13.3398 146.445 13.5021C146.162 13.835 146.009 14.259 146.014 14.6958V18.8218H144.482V13.8627C144.482 13.2509 144.46 12.5893 144.425 11.8731H145.783L145.855 12.9599H145.9C146.085 12.6132 146.361 12.3235 146.698 12.1218C147.119 11.8612 147.604 11.725 148.098 11.7288C148.396 11.7197 148.693 11.7708 148.971 11.8789C149.249 11.987 149.502 12.1499 149.715 12.358C150.247 12.8728 150.516 13.6438 150.516 14.661L150.503 18.8218Z"
          fill="white"
        />
        <path
          d="M160.895 13.0321H159.194V16.417C159.194 17.275 159.49 17.7028 160.094 17.7028C160.324 17.7081 160.554 17.6847 160.778 17.6331L160.823 18.802C160.436 18.9299 160.029 18.9888 159.622 18.9761C159.357 18.9895 159.092 18.9456 158.846 18.8473C158.6 18.749 158.377 18.5987 158.194 18.4066C157.851 18.0335 157.68 17.4118 157.68 16.5314V13.0321H156.667V11.8757H157.685V10.6023L159.199 10.1472V11.8881H160.895V13.0321Z"
          fill="white"
        />
        <path
          d="M169.067 18.8218H167.525V14.8725C167.525 13.6289 167.06 13.0072 166.122 13.0072C165.411 13.0072 164.919 13.3678 164.655 14.0866C164.6 14.267 164.575 14.4551 164.58 14.6436V18.8218H163.036V8.68726H164.58V12.8754H164.625C164.837 12.5148 165.142 12.2182 165.508 12.0168C165.875 11.8154 166.289 11.7168 166.707 11.7314C167 11.7225 167.293 11.7738 167.566 11.882C167.84 11.9902 168.088 12.153 168.296 12.3606C168.816 12.8853 169.079 13.6638 169.079 14.6934L169.067 18.8218Z"
          fill="white"
        />
        <path
          d="M177.504 15.0192C177.506 15.255 177.487 15.4906 177.447 15.723H172.814C172.804 16.0178 172.859 16.3113 172.975 16.5825C173.091 16.8537 173.265 17.0961 173.485 17.2923C173.914 17.6343 174.452 17.8108 175 17.7897C175.639 17.7996 176.275 17.6882 176.873 17.4614L177.121 18.5333C176.383 18.8356 175.591 18.9821 174.794 18.9636C173.719 18.9636 172.871 18.6453 172.254 18.016C171.638 17.3868 171.334 16.5238 171.334 15.4644C171.299 14.5171 171.603 13.5884 172.192 12.8455C172.482 12.4825 172.852 12.1926 173.274 11.999C173.696 11.8055 174.158 11.7138 174.622 11.7314C175.617 11.7314 176.374 12.1028 176.895 12.8455C177.321 13.4932 177.537 14.2568 177.512 15.0316M176.037 14.6337C176.058 14.2114 175.95 13.7927 175.729 13.4325C175.602 13.2242 175.421 13.0543 175.205 12.9409C174.989 12.8275 174.746 12.7747 174.503 12.7883C174.26 12.7841 174.02 12.8392 173.803 12.9489C173.586 13.0585 173.4 13.2194 173.259 13.4176C173 13.7681 172.843 14.1841 172.806 14.6188L176.037 14.6337Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_916">
          <rect
            width="196"
            height="56"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default AppleStore;
