const LocationIcon = ({ width, height, color='#4A4CCD' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 12.8001C12.8885 12.8001 13.7406 12.4472 14.3688 11.8189C14.997 11.1907 15.35 10.3386 15.35 9.4501C15.35 8.56162 14.997 7.70954 14.3688 7.08129C13.7406 6.45304 12.8885 6.1001 12 6.1001C11.1115 6.1001 10.2594 6.45304 9.63119 7.08129C9.00294 7.70954 8.64999 8.56162 8.64999 9.4501C8.64999 10.3386 9.00294 11.1907 9.63119 11.8189C10.2594 12.4472 11.1115 12.8001 12 12.8001Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12 2.75C5.3 2.75 4.183 8.333 5.3 12.565C6.283 16.273 9.23 18.807 11.174 20.885C11.2787 21 11.4062 21.0919 11.5485 21.1547C11.6907 21.2176 11.8445 21.2501 12 21.2501C12.1555 21.2501 12.3093 21.2176 12.4515 21.1547C12.5938 21.0919 12.7213 21 12.826 20.885C14.769 18.807 17.717 16.273 18.7 12.565C19.817 8.333 18.7 2.75 12 2.75Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default LocationIcon;
