const PhoneIcon = ({width,height,stroke="#4A4CCD"}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.751 11.198C17.751 9.85627 17.218 8.56949 16.2693 7.62075C15.3205 6.672 14.0338 6.139 12.692 6.139M21.219 10.844C21.219 9.781 21.0096 8.7284 20.6028 7.74633C20.196 6.76425 19.5997 5.87193 18.848 5.12032C18.0963 4.36871 17.2039 3.77253 16.2218 3.36583C15.2396 2.95913 14.187 2.74987 13.124 2.75M12.531 20.217C12.231 20.08 11.932 19.943 11.633 19.78C10.1661 18.9518 8.81873 17.9277 7.62802 16.736C6.08418 15.283 4.81608 13.5626 3.88502 11.658C3.38632 10.6352 3.04963 9.54118 2.88703 8.415C2.66571 7.27721 2.79185 6.09915 3.24903 5.034C3.53053 4.57623 3.87001 4.15676 4.25902 3.786C4.41698 3.60919 4.60904 3.46614 4.82368 3.36544C5.03831 3.26474 5.27109 3.20846 5.50802 3.2C6.00102 3.274 6.44803 3.532 6.75603 3.923C7.43003 4.672 8.15303 5.346 8.86403 6.057C9.14403 6.297 9.31902 6.637 9.35102 7.005C9.33902 7.315 9.22002 7.61 9.01402 7.841C8.77702 8.141 8.49002 8.415 8.21602 8.701C8.05016 8.86045 7.92327 9.05599 7.84523 9.27243C7.76719 9.48887 7.74008 9.72039 7.76602 9.949C7.93802 10.484 8.23302 10.972 8.62702 11.372C9.09984 12.0168 9.59515 12.6449 10.112 13.255C11.0756 14.368 12.2179 15.3126 13.492 16.05C13.6684 16.1832 13.8769 16.2672 14.0963 16.2936C14.3157 16.3199 14.5382 16.2876 14.741 16.2C15.164 15.963 15.541 15.65 15.851 15.277C16.1193 14.9499 16.5039 14.7395 16.924 14.69C17.298 14.71 17.65 14.87 17.91 15.14C18.246 15.426 18.533 15.763 18.845 16.075C19.157 16.387 19.407 16.612 19.669 16.898C19.9822 17.1746 20.2778 17.4705 20.554 17.784C20.768 18.061 20.871 18.409 20.841 18.757C20.7299 19.1738 20.4898 19.5449 20.155 19.817C19.6841 20.307 19.1119 20.6882 18.4783 20.934C17.8448 21.1798 17.1652 21.2842 16.487 21.24C15.1142 21.163 13.7691 20.8153 12.531 20.217Z"
        stroke={stroke}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </svg>
  );
};
export default PhoneIcon;
