const DirectionIcon = ({ width, height, stroke="#FAFAFA" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7768 8.8018L11.1978 13.3808M10.2058 12.7808L4.88977 11.8278C4.4665 11.7089 4.09165 11.4592 3.81879 11.1145C3.54593 10.7698 3.38903 10.3476 3.3705 9.90835C3.35198 9.46909 3.47278 9.03522 3.71565 8.66873C3.95852 8.30225 4.31101 8.02192 4.72277 7.8678L18.8328 2.8758C19.2058 2.73936 19.6101 2.7124 19.998 2.79808C20.3859 2.88376 20.7413 3.07852 21.0222 3.35941C21.3031 3.64031 21.4978 3.99563 21.5835 4.38353C21.6692 4.77142 21.6422 5.17572 21.5058 5.5488L16.5138 19.9348C16.3565 20.3311 16.0814 20.6695 15.7257 20.9046C15.37 21.1396 14.9508 21.2599 14.5246 21.2492C14.0984 21.2385 13.6857 21.0974 13.3422 20.8448C12.9988 20.5923 12.741 20.2404 12.6038 19.8368L11.6898 14.2648C11.6026 13.905 11.418 13.5761 11.1562 13.3143C10.8944 13.0526 10.5656 12.868 10.2058 12.7808Z"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default DirectionIcon;
