const RightArrowCircle = ({width,height,color='#4A4CCD'}) => {
  return (
    <svg
    //   width="21"
    //   height="20"
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.94413 13.7774L12.0925 10.6291C12.1805 10.5414 12.2503 10.4371 12.2979 10.3224C12.3456 10.2076 12.3701 10.0846 12.3701 9.96034C12.3701 9.83608 12.3456 9.71304 12.2979 9.59828C12.2503 9.48353 12.1805 9.3793 12.0925 9.29158L8.94413 6.22242M10.3333 17.9166C12.4329 17.9166 14.4466 17.0825 15.9312 15.5978C17.4159 14.1132 18.25 12.0995 18.25 9.99992C18.25 7.90029 17.4159 5.88665 15.9312 4.40199C14.4466 2.91733 12.4329 2.08325 10.3333 2.08325C8.23366 2.08325 6.22003 2.91733 4.73536 4.40199C3.2507 5.88665 2.41663 7.90029 2.41663 9.99992C2.41663 12.0995 3.2507 14.1132 4.73536 15.5978C6.22003 17.0825 8.23366 17.9166 10.3333 17.9166Z"
       // stroke="#4A4CCD"
       stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default RightArrowCircle;
