import { useMutation } from "@apollo/client";
import Footer from "../components/footer";
import Header from "../components/Header";
import LocationIcon from "../modules/icons/location";
import MailIcon from "../modules/icons/mail-icon";
import PhoneIcon from "../modules/icons/phone-iocn";
import PlaneIcon from "../modules/icons/plane-icon";
import RightArrrow from "../modules/icons/rightarrow";
import "../style/contactus.css";
import { CREATE_MESSAGE } from "../graphql/mutation/productMutations";
import { useForm } from "react-hook-form";
import emailjs from 'emailjs-com';
import toast, { Toaster } from "react-hot-toast";
import LoadingButton from "../modules/icons/loading-button";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ContactUs = () => {
  const { register, handleSubmit, reset } = useForm();
  const [createMessage, { loading }] = useMutation(CREATE_MESSAGE);
  const [isloading, setIsLoading] = useState(false);
  const { pathname } = useLocation();
  const SERVICE_ID = 'service_332qile';
  const TEMPLATE_ID = 'template_2ni6rd9';
  const USER_ID = 'sbX2AWKIJilaQyb__';
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleSubmitMessage = handleSubmit(async (credentials) => {
    // setIsLoading(true);

    // const templateParams = {
    //   from_name: credentials.username,
    //   phone_number: credentials.ph_number,
    //   message: credentials.message
    // };
    // try {
    //   await emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID);
    //   toast.success("Your message has been sent");
    //   reset();
    // } catch (err) {
    //   toast.error("Failed to send the message");
    // } finally {
    //   setIsLoading(false);
    // }
    try {
      await createMessage({
        variables: {
          username: credentials.username,
          ph_number: credentials.ph_number,
          message: credentials.message,
        },
      });
      toast.success("Your message has been sent");
      reset();
    } catch (err) {
      throw new Error("Error creating message");
    }
  });
  return (
    <div style={{ backgroundColor: "#B1B3B6" }}>
      <Toaster />
      <Header />
      <section className="contact-form-container">
        <div className="contact-location-container-layout">
          <div className="contact-location-layout">
            <div className="contact-loaction-container">
              <div className="contact-location">
                <p>Alpha International</p>
                <h2>Head Office</h2>
                <a href="https://maps.app.goo.gl/ReaXh7CAC7srwUH8A">
                  <div className="map-container"></div>
                </a>

                <div className="contact-description-detail">
                  <div className="description-detail-container">
                    <div className="contact-icon">
                      <LocationIcon width={24} height={24} />
                    </div>
                    <a
                      className="description-detail-container"
                      href="https://maps.app.goo.gl/ReaXh7CAC7srwUH8A"
                    >
                      No. 297 , Malar Myaing Street, Thaketa Industrial Zone,
                      Thaketa Township
                    </a>
                  </div>
                  <a
                    href="mailto:info@alphaelectronicmm.com"
                    className="description-detail-container"
                  >
                    <div className="contact-icon">
                      <MailIcon width={24} height={24} />
                    </div>
                    <p>
                      {" "}
                      <a>info@alphaelectronicmm.com</a>
                    </p>
                  </a>
                  <a
                    href="tel:09428694996"
                    className="description-detail-container"
                  >
                    <div className="contact-icon">
                      <PhoneIcon width={24} height={24} />
                    </div>
                    <p>09-428694996</p>
                  </a>
                  <a
                    href="tel:09762509157"
                    className="description-detail-container"
                  >
                    <div className="contact-icon">
                      <PhoneIcon width={24} height={24} />
                    </div>
                    <p>09-762509157</p>
                  </a>
                  <a
                    href="tel:09884888693"
                    className="description-detail-container"
                  >
                    <div className="contact-icon">
                      <PhoneIcon width={24} height={24} />
                    </div>
                    <p>09-884888693</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="contact-from-container">
              <div className="contact-form-container-layout">
                <h2>Get In Touch</h2>
                <p>
                  Please drop a message if you have any questions. We’ll get
                  back to you soon.
                </p>
                <div className="contact-form">
                  <form action="" onSubmit={handleSubmitMessage}>
                    <div className="input-container">
                      <div className="input-icon-container">
                        <PlaneIcon width={24} height={24} />
                        <label htmlFor="">Name</label>
                      </div>

                      <input
                        name="username"
                        type="text"
                        placeholder="Enter Your Name"
                        {...register("username", {
                          required: "username is required",
                        })}
                      />
                    </div>
                    <div className="input-container">
                      <div className="input-icon-container">
                        <PhoneIcon width={24} height={24} />
                        <label htmlFor="">Phone Number</label>
                      </div>

                      <input
                        name="ph_number"
                        type="number"
                        placeholder="Enter Your Phone Number"
                        {...register("ph_number", {
                          required: "ph_number is required",
                        })}
                      />
                    </div>
                    <div className="text-area-container">
                      <div className="input-icon-container">
                        <MailIcon width={24} height={24} />
                        <label htmlFor="">Message</label>
                      </div>

                      <textarea
                        name="message"
                        onResize={false}
                        type="text"
                        placeholder="Enter Message"
                        {...register("message", {
                          required: "message is required",
                        })}
                      />
                    </div>
                    <div className="form-button">
                      <button type="submit">
                        {loading ? (
                          <LoadingButton />
                        ) : (
                          <div className="message-subit-icon">
                            <p>Send Message</p>{" "}
                            <RightArrrow width={24} height={24} />
                          </div>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default ContactUs;
