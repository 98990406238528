import { useNavigate } from "react-router-dom";
import ClockIcon from "../modules/icons/clock";
import DirectionIcon from "../modules/icons/direction";
import LocationIcon from "../modules/icons/location";
import PhoneIcon from "../modules/icons/phone-iocn";

const StoreAndService = () => {
  const navigate = useNavigate();
  return (
    <div className="storeandservice-container">
      <div className="storeandservice-layout">
        <h1>Our Stores</h1>
        <div className="store-service-description-container">
          <div className="store-service-description">
            <div className="store-service-description-img-container">
              <img src="/images/storeservice/store-s-y-n.jpg" alt="storeandservice" />
            </div>
            <div className="store-service-description-text">
              <h4>Alpha Store 1</h4>
              <div className="store-service-detail-text">
                <div>
                  <ClockIcon width={24} height={24} />{" "}
                </div>{" "}
                <p>Opening Time : 9 AM to 7 PM</p>
              </div>
              <div className="store-service-detail-text">
                <div>
                  <PhoneIcon width={24} height={24} />
                </div>
                <a href="tel:09968323351">09-968 323 351</a>,
                <a href="tel:09428694996">09-428 694 996</a>
              </div>
              <div className="store-service-detail-text">
                <div>
                  <LocationIcon width={24} height={24} />
                </div>
                <a href="https://www.google.com/maps/search/san+yeik+nyein+ga+mone+pwint+/@16.8329504,96.12742,18z?entry=ttu&g_ep=EgoyMDI0MDkwOC4wIKXMDSoASAFQAw%3D%3D">
                  Gamone Pwint (San Yeik Nyein) No. 96/108, Gamone Pwint
                  Shopping Mall (San Yeik Nyein), 3rd Floor, Shop No. 138,
                  Kamayut Township{" "}
                </a>
              </div>
            </div>
            <div className="store-service-btn-container">
              <a href="https://www.google.com/maps/search/san+yeik+nyein+ga+mone+pwint+/@16.8329504,96.12742,18z?entry=ttu&g_ep=EgoyMDI0MDkwOC4wIKXMDSoASAFQAw%3D%3D">
                {" "}
                <p>Get Direction</p> <DirectionIcon width={24} height={24} />
              </a>
            </div>
          </div>
          <div className="store-service-description">
            <div className="store-service-description-img-container">
              <img src="/images/storeservice/store-n-o.jpg" alt="storeandservice" />
            </div>
            <div className="store-service-description-text">
              <h4>Alpha Store 2</h4>
              <div className="store-service-detail-text">
                <div>
                  <ClockIcon width={24} height={24} />{" "}
                </div>
                <p>Opening Time : 9 AM to 7 PM</p>
              </div>
              <div className="store-service-detail-text">
                <div>
                  <PhoneIcon width={24} height={24} />
                </div>
                <a href="tel:09251269675">09-251 269 675</a>,
                <a href="tel:09428694996">09-428 694 996</a>
              </div>
              <div className="store-service-detail-text">
                <div>
                  <LocationIcon width={24} height={24} />
                </div>
                <a href="https://maps.app.goo.gl/kCepuaJX5wVpETPu8">
                  (North Okkalapa) No.5/A, Thudhammar Road, Nya Quarter, Near
                  Six Turn Traffic Light, North Okkalapa Township, Yangon Region
                </a>
              </div>
            </div>
            <div className="store-service-btn-container">
              <a href="https://maps.app.goo.gl/kCepuaJX5wVpETPu8">
                {" "}
                <p>Get Direction</p> <DirectionIcon width={24} height={24} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreAndService;
