import { gql } from "@apollo/client";

export const DELETE_MESSAGE_BY_ID = gql`
  mutation DeleteMessageById($id: uuid!) {
    delete_message_by_pk(message_id: $id) {
      message_id
    }
  }
`;

export const DELETE_MESSAGES_BY_READ = gql`
  mutation DeleteMessagesByRead($read: Boolean!) {
    delete_message(where: { read: { _eq: $read } }) {
      affected_rows
    }
  }
`;


export const UPDATE_MESSAGE_READ_STATUS = gql`
  mutation UpdateMessageReadStatus($id: uuid!, $read: Boolean!) {
    update_message_by_pk(
      pk_columns: { message_id: $id }
      _set: { read: $read }
    ) {
      message_id
      read
    }
  }
`;