const TiktokLogo = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_98_269)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0H18C19.1046 0 20 0.89536 20 2V18C20 19.1046 19.1046 20 18 20H2C0.89536 20 0 19.1046 0 18V2C0 0.89536 0.89536 0 2 0Z"
          fill="#010101"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5584 18.4C13.167 18.4 13.6603 17.9106 13.6603 17.307C13.6603 16.7034 13.167 16.214 12.5584 16.214H12.3924C13.001 16.214 13.4943 16.7034 13.4943 17.307C13.4943 17.9106 13.001 18.4 12.3924 18.4H12.5584Z"
          fill="#EE1D51"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3774 16.214H12.2114C11.6028 16.214 11.1094 16.7034 11.1094 17.307C11.1094 17.9106 11.6028 18.4 12.2114 18.4H12.3774C11.7687 18.4 11.2754 17.9106 11.2754 17.307C11.2754 16.7034 11.7687 16.214 12.3774 16.214Z"
          fill="#66C8CF"
        />
        <path
          d="M12.3849 17.8385C12.6809 17.8385 12.9208 17.6005 12.9208 17.307C12.9208 17.0135 12.6809 16.7756 12.3849 16.7756C12.0889 16.7756 11.849 17.0135 11.849 17.307C11.849 17.6005 12.0889 17.8385 12.3849 17.8385Z"
          fill="#010101"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 15.72V16.274H4.64896V18.385H5.29816V16.289H5.8264L6.00752 15.72H4ZM9.3132 15.72V16.274H9.96216V18.385H10.6114V16.289H11.1396L11.3207 15.72H9.3132ZM6.1284 16.0344C6.1284 15.8608 6.27008 15.72 6.44512 15.72C6.62032 15.72 6.76216 15.8608 6.76216 16.0344C6.76216 16.208 6.6204 16.3488 6.44512 16.3488C6.27008 16.3487 6.1284 16.208 6.1284 16.0344ZM6.1284 16.5734H6.76216V18.385H6.1284V16.5734ZM7.03392 15.72V18.385H7.66792V17.6964L7.86416 17.5166L8.48296 18.4H9.16216L8.27152 17.1124L9.07152 16.3338H8.30176L7.66792 16.9626V15.72H7.03392ZM13.8715 15.72V18.385H14.5056V17.6964L14.7018 17.5166L15.3207 18.4H16L15.1094 17.1124L15.9094 16.3338H15.1396L14.5056 16.9626V15.72H13.8715Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8934 6.49488C13.7689 7.1204 14.8415 7.48848 15.9999 7.48848V5.2604C15.7807 5.26049 15.562 5.23762 15.3475 5.19216V6.946C14.1892 6.946 13.1167 6.57792 12.241 5.9524V10.4992C12.241 12.7738 10.3962 14.6175 8.12056 14.6175C7.27152 14.6175 6.48232 14.361 5.82672 13.921C6.57496 14.6856 7.6184 15.16 8.7728 15.16C11.0486 15.16 12.8934 13.3162 12.8934 11.0416L12.8934 6.49488ZM13.6982 4.24704C13.2507 3.75848 12.957 3.12704 12.8934 2.42896V2.1424H12.2751C12.4307 3.0296 12.9615 3.7876 13.6982 4.24704ZM7.266 12.1756C7.01604 11.848 6.88092 11.4472 6.88152 11.0351C6.88152 9.9948 7.72536 9.15136 8.7664 9.15136C8.96039 9.15123 9.15324 9.18099 9.33816 9.2396V6.96176C9.12209 6.93217 8.90403 6.91962 8.686 6.92424V8.6972C8.501 8.63859 8.30807 8.60883 8.114 8.60896C7.07296 8.60896 6.2292 9.4524 6.2292 10.4928C6.2292 11.2284 6.65096 11.8654 7.266 12.1756Z"
          fill="#EE1D52"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.241 5.9524C13.1167 6.57792 14.1892 6.946 15.3475 6.946V5.19216C14.701 5.05456 14.1286 4.7168 13.6982 4.24704C12.9615 3.7876 12.4307 3.0296 12.2751 2.1424H10.6512V11.0415C10.6475 12.079 9.80512 12.919 8.76632 12.919C8.15416 12.919 7.6104 12.6274 7.266 12.1756C6.65096 11.8654 6.2292 11.2284 6.2292 10.4928C6.2292 9.4524 7.07296 8.60896 8.114 8.60896C8.31344 8.60896 8.50568 8.64 8.686 8.6972V6.92424C6.4504 6.9704 4.65232 8.79616 4.65232 11.0416C4.65232 12.1625 5.10008 13.1786 5.82672 13.921C6.48232 14.361 7.27152 14.6175 8.12056 14.6175C10.3962 14.6175 12.241 12.7738 12.241 10.4992V5.9524Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3475 5.19216L15.3475 4.71792C14.7644 4.7188 14.1929 4.55567 13.6982 4.24704C14.1361 4.72622 14.7127 5.0566 15.3475 5.19216ZM12.2751 2.1424C12.2603 2.05762 12.2489 1.97227 12.241 1.88656V1.6H9.99872V10.4992C9.99512 11.5366 9.1528 12.3766 8.11392 12.3766C7.80888 12.3766 7.52104 12.3042 7.266 12.1756C7.6104 12.6274 8.15416 12.919 8.76632 12.919C9.80512 12.919 10.6475 12.079 10.6512 11.0415V2.1424H12.2751ZM8.686 6.92424L8.686 6.41936C8.49864 6.39374 8.30975 6.38094 8.12064 6.38104C5.8448 6.38104 4 8.22488 4 10.4992C4 11.9251 4.72496 13.1818 5.82672 13.921C5.10008 13.1786 4.65232 12.1625 4.65232 11.0416C4.65232 8.79616 6.4504 6.9704 8.686 6.92424Z"
          fill="#69C9D0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3924 18.4C13.001 18.4 13.4943 17.9106 13.4943 17.307C13.4943 16.7034 13.001 16.214 12.3924 16.214H12.3774C11.7687 16.214 11.2754 16.7034 11.2754 17.307C11.2754 17.9106 11.7687 18.4 12.3774 18.4H12.3924ZM11.849 17.307C11.849 17.0135 12.0889 16.7756 12.3849 16.7756C12.6809 16.7756 12.9208 17.0135 12.9208 17.307C12.9208 17.6005 12.6809 17.8385 12.3849 17.8385C12.0889 17.8385 11.849 17.6005 11.849 17.307Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_98_269">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default TiktokLogo;
