import { gql } from "@apollo/client";

export const GET_PRODUCTS = gql`
  query getProducts($orderBy: order_by = asc) {
    product(order_by: { created_at: $orderBy }) {
      id
      name
      model
      price
      image_url
      category_id
      subcategory_id
      category {
        id
        category_name
        subcategories {
          id
          subcategory_name
        }
      }
      subcategory {
        id
        subcategory_name
      }
      product_specification
      product_description
      sub_img_one_url
      sub_img_two_url
      sub_img_three_url
    }
  }
`;

export const GET_PRODUCTS_SORTED_BY_PRICE = gql`
  query getProductsSortedByPrice($orderBy: order_by = asc) {
    product(order_by: { price: $orderBy }) {
      id
      name
      model
      price
      image_url
      category_id
      subcategory_id
      category {
        id
        category_name
        subcategories {
          id
          subcategory_name
        }
      }
      subcategory {
        id
        subcategory_name
      }
      product_specification
      product_description
      sub_img_one_url
      sub_img_two_url
      sub_img_three_url
    }
  }
`;

export const GET_PRODUCT_BY_ID = gql`
  query getProductById($id: uuid!) {
    product_by_pk(id: $id) {
      id
      name
      category
      model
      price
      image_url
      category_id
      subcategory_id
      category {
        id
        category_name
        subcategories {
          id
          subcategory_name
        }
      }
      subcategory {
        id
        subcategory_name
      }
      product_specification
      product_description
      sub_img_one_url
      sub_img_two_url
      sub_img_three_url
    }
  }
`;

export const GET_PRODUCTS_BY_CATEGORY = gql`
  query getProductsByCategory(
    $category: String!
    $orderBy: [product_order_by!]
  ) {
    product(
      where: { category: { category_name: { _eq: $category } } }
      order_by: $orderBy
    ) {
      id
      name
      category
      model
      price
      image_url
      category_id
      subcategory_id
      category {
        id
        category_name
        subcategories {
          id
          subcategory_name
        }
      }
      subcategory {
        id
        subcategory_name
      }
      product_specification
      product_description
      sub_img_one_url
      sub_img_two_url
      sub_img_three_url
    }
  }
`;

export const GET_PRODUCTS_BY_SUBCATEGORY = gql`
  query getProductsBySubCategory($subcategory: String!) {
    product(
      where: { subcategory: { subcategory_name: { _eq: $subcategory } } }
      order_by: { created_at: desc }
    ) {
      id
      name
      model
      price
      image_url
      category_id
      subcategory_id
      category {
        id
        category_name
        subcategories {
          id
          subcategory_name
        }
      }
      subcategory {
        id
        subcategory_name
      }
      product_specification
      product_description
      sub_img_one_url
      sub_img_two_url
      sub_img_three_url
      created_at
    }
  }
`;

export const GET_CATEGORIES = gql`
  query getCategories {
    category {
      id
      category_name
      products {
        id
        name
        model
        price
        image_url
        category_id
        subcategory_id
        category {
          id
          category_name
          subcategories {
            id
            subcategory_name
          }
        }
        subcategory {
          id
          subcategory_name
        }
        product_specification
        product_description
        sub_img_one_url
        sub_img_two_url
        sub_img_three_url
      }
      subcategories {
        id
        subcategory_name
      }
    }
  }
`;

export const GET_CATEGORY_BY_NAME = gql`
  query getCategories($category_name: String!) {
    category(where: { category_name: { _eq: $category_name } }) {
      id
      category_name
      subcategories {
        id
        subcategory_name
        products(order_by: { created_at: desc }) {
          id
          name
          model
          price
          image_url
        }
        category {
          id
          category_name
        }
      }
      products(order_by: { created_at: desc }) {
        id
        name
        model
        price
        image_url
        category_id
        subcategory_id
        category {
          id
          category_name
          subcategories {
            id
            subcategory_name
          }
        }
        subcategory {
          id
          subcategory_name
        }
        product_specification
        product_description
        sub_img_one_url
        sub_img_two_url
        sub_img_three_url
        created_at
      }
    }
  }
`;

export const GET_CATEGORIES_WITHOUT_SUB = gql`
  query getCategories {
    category {
      id
      category_name
    }
  }
`;

export const GET_SUBCATEGORIES = gql`
  query getSubCategories {
    subcategory {
      id
      subcategory_name
    }
  }
`;

export const GET_SUBCATEGORY_BY_PRODUCT_ID = gql`
  query getSubCategory($id: uuid!) {
    subcategory(where: { products: { id: { _eq: $id } } }) {
      id
      subcategory_name
      products {
        id
        name
        model
        price
        image_url
      }
    }
  }
`;
