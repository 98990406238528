const YoutubeLogo = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5634 5.18635C19.4484 4.76216 19.2243 4.37546 18.9135 4.06468C18.6028 3.75389 18.2161 3.52984 17.7919 3.41479C16.2388 2.99072 9.9875 2.99072 9.9875 2.99072C9.9875 2.99072 3.73594 3.00322 2.1825 3.42729C1.75831 3.54234 1.37161 3.76639 1.06083 4.07718C0.750044 4.38796 0.525993 4.77466 0.410938 5.19885C0 6.75229 0 10.0001 0 10.0001C0 10.0001 0 13.2479 0.42375 14.8138C0.538806 15.238 0.762857 15.6247 1.07364 15.9355C1.38443 16.2463 1.77112 16.4704 2.19531 16.5854C3.74844 17.0095 10 17.0095 10 17.0095C10 17.0095 16.2516 17.0095 17.805 16.5857C18.2292 16.4707 18.6159 16.2466 18.9267 15.9358C19.2375 15.625 19.4615 15.2383 19.5766 14.8142C20 13.2607 20 10.0001 20 10.0001C20 10.0001 19.9875 6.75229 19.5634 5.18635Z"
        fill="#FF0000"
      />
      <path
        d="M7.9975 13.0038L13.1834 10.0001L7.99719 6.99635L7.9975 13.0038Z"
        fill="white"
      />
    </svg>
  );
};
export default YoutubeLogo;
