const PlaneIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2768 8.80199L10.6978 13.381M9.70577 12.781L4.38977 11.828C3.9665 11.7091 3.59165 11.4594 3.31879 11.1147C3.04593 10.77 2.88903 10.3478 2.8705 9.90853C2.85198 9.46927 2.97278 9.0354 3.21565 8.66892C3.45852 8.30243 3.81101 8.0221 4.22277 7.86799L18.3328 2.87598C18.7058 2.73955 19.1101 2.71258 19.498 2.79826C19.8859 2.88394 20.2413 3.0787 20.5222 3.3596C20.8031 3.64049 20.9978 3.99582 21.0835 4.38371C21.1692 4.7716 21.1422 5.17591 21.0058 5.54898L16.0138 19.935C15.8565 20.3312 15.5814 20.6697 15.2257 20.9048C14.87 21.1398 14.4508 21.2601 14.0246 21.2494C13.5984 21.2387 13.1857 21.0976 12.8422 20.845C12.4988 20.5924 12.241 20.2406 12.1038 19.837L11.1898 14.265C11.1026 13.9052 10.918 13.5763 10.6562 13.3145C10.3944 13.0527 10.0656 12.8681 9.70577 12.781Z"
        stroke="#4A4CCD"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default PlaneIcon;
