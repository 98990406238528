import { gql } from "@apollo/client";

export const GET_ALL_MESSAGES = gql`
  query GetAllMessages($limit: Int!, $offset: Int!) {
    message_aggregate {
      aggregate {
        count
      }
    }
    message(limit: $limit, offset: $offset, order_by: { created_at: desc }) {
      message_id
      username
      ph_number
      message
      read
      created_at
    }
  }
`;


export const GET_FILTERED_MESSAGES = gql`
  query GetFilteredMessages($limit: Int!, $offset: Int!, $read: Boolean!) {
    message_aggregate(where: { read: { _eq: $read } }) {
      aggregate {
        count
      }
    }
    message(limit: $limit, offset: $offset, where: { read: { _eq: $read } }) {
      message_id
      username
      ph_number
      message
      read
      created_at
    }
  }
`;

export const GET_MESSAGE_BY_ID = gql`
  query GetMessageById($id: uuid!) {
    message_by_pk(message_id: $id) {
      message_id
      username
      ph_number
      message
      read
      created_at
    }
  }
`;

